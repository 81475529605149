.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		border-radius: 50%;
		background-color: $white-5;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators1 {
	&.carousel-indicators {
		position: absolute;
		right: 0;
		top: 10px;
		left: 0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-right: 15%;
		margin-left: 15%;
		list-style: none;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators2 {
	&.carousel-indicators {
		position: absolute;
		right: 0px;
		left: auto;
		top: 0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-top: 10px;
		list-style: none;
		margin-left:5%;
		margin-right:5%;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators3 {
	&.carousel-indicators {
		position: absolute;
		left: 0px;
		right: auto;
		top:0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-top: 10px;
		list-style: none;
		margin-left:5%;
		margin-right:5%;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators4 {
	&.carousel-indicators {
		position: absolute;
		right: 0px;
		left: auto;
		bottom: 0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-top: 10%;
		list-style: none;
		margin-left:5%;
		margin-right:5%;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-indicators5 {
	&.carousel-indicators {
		position: absolute;
		bottom: 0;
		left: 0px;
		right: auto;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		list-style: none;
		margin-left:5%;
		margin-right:5%;
	}
	li {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 7px;
		height: 7px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: $white-5;
		border-radius: 50%;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
		&::after {
			position: absolute;
			bottom: -10px;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 10px;
			content: "";
		}
	}
	.active {
		background-color: $white;
	}
}

.carousel-caption {
	position: absolute;
	right: 15%;
	top: 30%;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: $white;
	text-align: center;
}

.carousel-item-background {
	content: '';
	background: $black-5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@media (max-width: 990px) and (min-width: 767px) {
	.carousel-caption {
		top: 10%;
	}
}

.carousel-bg {
	.prev-icon,
	.next-icon {
		background-color: $black-7;
		padding: 5px 15px;
		border-radius: 100px;
		font-size: 20px;
	}
}

.carousel-control {
	&:active,
	&:focus,
	&:hover {
		color: #333;
	}
}

.carousel-caption,
.carousel-control .fa {
	font: normal normal normal 30px/26px FontAwesome;
}

.carousel .carousel-indicators {
	bottom: -1rem;
}

.carousel-indicators li {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	margin: 1px 2px;
	&.active {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin: 1px 2px;
		border: 5px double;
	}
	background: #e2e2e2;
	border: 4px solid $white;
}

.carousel .carousel-control {
	height: 40px;
	width: 40px;
	background: 0 0;
	margin: auto 0;
	border-radius: 50%;
	background: $black-6;
	z-index: 999;
	i {
		font-size: 32px;
		position: absolute;
		top: 1.6rem;
		display: inline-block;
		margin: -18px 0 0 0;
		z-index: 5;
		left: 0;
		right: 0;
		color: $white-9;
		text-shadow: none;
		font-weight: 700;
		z-index: 999;
	}
}

.carousel-control-next {
	right: -1.8rem;
}

.carousel-control-prev {
	left: -1.8rem;
}