.tab-content> {
	.tab-pane {
		display: none;
	}
	.active {
		display: block;
	}
}

@media (max-width: 480px) {
	.tab-content.card-body {
		.btn {
			min-width: 1.625rem;
		}
		.progress-bar {
			display: none;
		}
	}
}

.tabs-menu ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		color: $white;
		border-radius: 25px;
	}
}

.tabs-menu1 ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		border-radius: 3px;
	}
}

.tabs-menu-body {
	padding: 15px;
	border: 1px solid #e0e8f3;
	p:last-child {
		margin-bottom: 0;
	}
}

.tab-menu-heading {
	padding: 20px;
	border: 1px solid #e0e8f3;
	border-bottom: 0;
}

.tab_wrapper {
	.content_wrapper .tab_content.active p:last-child {
		margin-bottom: 0;
	}
	>ul {
		li {
			border: 1px solid #e0e8f3;
			border-top: 3px solid #e0e8f3;
		}
		border-bottom: 1px solid #e0e8f3;
	}
	&.right_side {
		.content_wrapper {
			border: 1px solid #e0e8f3;
		}
		>ul {
			li {
				&.active {
					border-color: #e0e8f3;
				}
				&:after {
					background: #e0e8f3;
				}
			}
			border-bottom: 1px solid #e0e8f3;
		}
	}
}

#tab-11 .item-card2-img:hover a,
#tab-12 .item-card2-img:hover a {
	background: 0 0;
}

.tabs-menu ul.eductaional-tabs li a {
	padding: 10px 20px 11px 20px;
	background: #d8dee6;
	border-radius: 3px;
	margin: 10px;
	&:hover {
		color: #2e384d;
		background: #e1e8f1 !important;
	}
}

@media (max-width: 480px) {
	.tabs-menu ul.eductaional-tabs li {
		a {
			margin: 0;
		}
		width: 100% !important;
	}
	.tab-content .tab-pane .card-body a {
		width: 100%;
		display: block;
		&.float-right {
			float: none !important;
		}
	}
}