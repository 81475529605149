.card .card-block {
	padding: 15px;
	p {
		line-height: 1.4;
		margin-top: 10px;
	}
}

.card-blog-overlay6 i {
	font-size: 3rem;
}

.card-counter2 i {
	font-size: 4.5em;
}

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: $white;
	background-clip: border-box;
	border: 1px solid #e0e8f3;
	border-radius: 3px;
	>hr {
		margin-right: 0;
		margin-left: 0;
	}
	&:hover {
		box-shadow: 0 5px 12px rgba(126, 142, 177, 0.2);
	}
	>.list-group {
		&:first-child .list-group-item:first-child {
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}
		&:last-child .list-group-item:last-child {
			border-bottom-right-radius: 7px;
			border-bottom-left-radius: 7px;
		}
	}
}

.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.5rem;
}

.card-title {
	margin-bottom: 1.5rem;
}

.card-subtitle {
	margin-top: -.75rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link {
	&:hover {
		text-decoration: none;
	}
	+.card-link {
		margin-left: 1.5rem;
	}
}

.card-header {
	padding: 1.5rem 1.5rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid #e0e8f3;
	+.list-group .list-group-item:first-child {
		border-top: 0;
	}
}

.card-footer {
	padding: 1.5rem 1.5rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid #e0e8f3;
	&:last-child {
		border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
	}
}

.card-header-tabs {
	margin-right: -.75rem;
	margin-bottom: -1.5rem;
	margin-left: -.75rem;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -.75rem;
	margin-left: -.75rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}

.card-img {
	width: 100%;
	border-radius: calc(3px - 1px);
	height: 100%;
}

.card-img-top {
	width: 100%;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);
}

.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(3px - 1px);
	border-bottom-left-radius: calc(3px - 1px);
}

.card-deck {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	.card {
		margin-bottom: .75rem;
	}
}

@media (min-width: 576px) {
	.card-deck {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		margin-right: -.75rem;
		margin-left: -.75rem;
		.card {
			display: -ms-flexbox;
			display: flex;
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-right: .75rem;
			margin-bottom: 0;
			margin-left: .75rem;
		}
	}
}

.card-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	>.card {
		margin-bottom: .75rem;
	}
}

@media (min-width: 576px) {
	.card-group {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		>.card {
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			margin-bottom: 0;
			+.card {
				margin-left: 0;
				border-left: 0;
			}
			&:first-child {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				.card-header,
				.card-img-top {
					border-top-right-radius: 0;
				}
				.card-footer,
				.card-img-bottom {
					border-bottom-right-radius: 0;
				}
			}
			&:last-child {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				.card-header,
				.card-img-top {
					border-top-left-radius: 0;
				}
				.card-footer,
				.card-img-bottom {
					border-bottom-left-radius: 0;
				}
			}
			&:only-child {
				border-radius: 3px;
				.card-header,
				.card-img-top {
					border-top-left-radius: 3px;
					border-top-right-radius: 3px;
				}
				.card-footer,
				.card-img-bottom {
					border-bottom-right-radius: 3px;
					border-bottom-left-radius: 3px;
				}
			}
			&:not(:first-child):not(:last-child):not(:only-child) {
				border-radius: 0;
				.card-footer,
				.card-header,
				.card-img-bottom,
				.card-img-top {
					border-radius: 0;
				}
			}
		}
	}
}

.card-columns .card {
	margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
		.card {
			display: inline-block;
			width: 100%;
		}
	}
}

.card-blog-overlay .card-header {
	border-bottom: 1px solid $white-2;
}

.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
	border-top: 1px solid $white-2;
}

.card-blog-overlay {
	background: url(../images/media/thumbnails/thumb2.jpg);
	background-size: cover;
	position: relative;
}

.card-blog-overlay1:before,
.card-blog-overlay2:before,
.card-blog-overlay:before {
	content: '';
	display: block;
	position: absolute;
	background: $black-6;
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
}

.card-blog-overlay1 {
	background: url(../images/media/thumbnails/thumb3.jpg);
	background-size: cover;
}

.card-blog-overlay2 {
	background: url(../images/banners/banner4.jpg);
	background-size: cover;
}

.card-blog-overlay3 {
	background: url(../images/media/0-4.jpg);
	background-size: cover;
}

.card {
	position: relative;
	margin-bottom: 1.5rem;
	width: 100%;
	.card {
		box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.1), 0 2px 9px 0 rgba(62, 57, 107, 0.1);
	}
}

@media print {
	.card {
		box-shadow: none;
		border: none;
	}
}

.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0;
	padding: 1.5rem 1.5rem;
	position: relative;
	height: 100%;
	+.card-body {
		border-top: 1px solid #e0e8f3;
	}
	> :last-child {
		margin-bottom: 0;
	}
}

@media print {
	.card-body {
		padding: 0;
	}
}

.card-body-scrollable {
	overflow: auto;
}

.card-bottom {
	padding: 1rem 1.5rem;
	background: 0 0;
}

.card-footer {
	padding: 1rem 1.5rem;
	background: 0 0;
	border-top: 1px solid #e0e8f3;
	color: #5f6877;
}

.card-header {
	background: 0 0;
	padding: .5rem 1.5rem;
	display: -ms-flexbox;
	display: flex;
	min-height: 3.5rem;
	-ms-flex-align: center;
	align-items: center;
	.card-title {
		margin-bottom: 0;
	}
	&.border-0+.card-body {
		padding-top: 0;
	}
}

@media print {
	.card-header {
		display: none;
	}
}

.card-img-overlay {
	background-color: $black-4;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}

.card-title {
	font-size: 1.125rem;
	line-height: 1.2;
	font-weight: 500;
	a {
		color: inherit;
	}
	&:only-child {
		margin-bottom: 0;
		font-weight: 700;
	}
}

.card-subtitle,
.card-title small {
	color: #6987ab;
	font-size: .875rem;
	display: block;
	margin: -.75rem 0 1rem;
	line-height: 1.1;
	font-weight: 400;
}

.card-table {
	margin-bottom: 0;
	tr {
		&:first-child {
			td,
			th {
				border-top: 0;
			}
		}
		td:first-child,
		th:first-child {
			padding-left: 1.5rem;
		}
		td:last-child,
		th:last-child {
			padding-right: 1.5rem;
		}
	}
}

.card-body+.card-table {
	border-top: 1px solid #e0e8f3;
}

.card-profile .card-header {
	height: 5rem;
	background-size: cover;
	border: 0;
}

.card-profile-img {
	max-width: 6rem;
	margin-bottom: 1rem;
	border: 3px solid $white;
	border-radius: 100%;
	box-shadow: 0 1px 1px $black-1;
}

.card-link+.card-link {
	margin-left: 1rem;
}

.card-body+.card-list-group {
	border-top: 1px solid #e0e8f3;
}

.card-list-group .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	&:last-child {
		border-bottom: 0;
	}
	&:first-child {
		border-top: 0;
	}
}

.card-header-tabs {
	margin: -1.25rem 0;
	border-bottom: 0;
	line-height: 2rem;
	.nav-item {
		margin-bottom: 1px;
	}
}

.card-header-pills {
	margin: -.75rem 0;
}

.card-aside {
	-ms-flex-direction: row;
	flex-direction: row;
}

.card-aside-column {
	min-width: 5rem;
	width: 30%;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	background: no-repeat center/cover;
}

.card-value {
	font-size: 1rem;
	line-height: 3.4rem;
	height: 3.4rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;
	i {
		vertical-align: middle;
	}
}

.card-chart-bg {
	height: 4rem;
	margin-top: -1rem;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.card-options {
	margin-left: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-order: 100;
	order: 100;
	margin-right: -.5rem;
	color: #6987ab;
	-ms-flex-item-align: center;
	align-self: center;
	a:not(.btn) {
		margin-left: .5rem;
		color: #b0bcd0;
		font-size: 14px;
		display: inline-block;
		min-width: 1rem;
		&:hover {
			text-decoration: none;
			color: #6987ab;
		}
		i {
			font-size: 1rem;
			vertical-align: middle;
		}
	}
	.dropdown-toggle:after {
		display: none;
	}
}

.card-img-absolute {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
}

.card-collapsed {
	> :not(.card-header):not(.card-status) {
		display: none;
	}
	.card-options-collapse i:before {
		content: '\e92d';
	}
}

.card-fullscreen {
	.card-options-fullscreen i:before {
		content: '\e992';
	}
	.card-options-remove {
		display: none;
	}
}

.card-map {
	height: 15rem;
	background: #e0e8f3;
}

.card-map-placeholder {
	background: no-repeat center;
}

.card-tabs {
	display: -ms-flexbox;
	display: flex;
}

.card-tabs-bottom .card-tabs-item {
	border: 0;
	&.active {
		border-top-color: $white;
	}
}

.card-tabs-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: block;
	padding: 1.4rem 1.5rem;
	color: inherit;
	overflow: hidden;
}

a.card-tabs-item {
	background: #fafbfc;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
	&:focus {
		z-index: 1;
	}
	&.active {
		background: $white;
		border-bottom-color: $white;
	}
}

.card-status {
	position: absolute;
	top: -1px;
	left: 0;
	right: 0;
	height: 3px;
	border-radius: 7px 7px 0 0;
	background: rgba(0, 40, 100, 0.12);
}

.card-status-left {
	right: auto;
	bottom: 0;
	height: auto;
	width: 3px;
	border-radius: 3px 0 0 3px;
}

.card-icon {
	width: 3rem;
	font-size: 2.5rem;
	line-height: 3rem;
	text-align: center;
}

.card-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
}

.card-alert {
	border-radius: 0;
	margin: -1px -1px 0;
}

.card-category {
	font-size: 18px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	background: #e0e8f3;
}

.card-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.card-aside-img {
	img {
		width: 100px !important;
		height: 80px !important;
		vertical-align: middle;
	}
	&.wrap-border img {
		border: 1px solid #e0e8f3;
		padding: 3px;
	}
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.card-item-desc {
	padding: 15px;
	h6 {
		margin-top: 5px;
	}
}

.card-blogs .card {
	border: none;
	box-shadow: none;
}

.card-blogs1 {
	.card {
		box-shadow: none;
		border-bottom: 1px solid #e0e8f3;
		padding-bottom: 15px;
		margin-bottom: 10px;
		&:last-child {
			box-shadow: none;
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
	.card-item-desc .card-item-price {
		.newprice {
			font-weight: 600;
		}
		.oldprice {
			font-size: 14px;
		}
	}
}

.card-blogs2 .card {
	border: none;
	box-shadow: none;
}

.card-img-overlay1 {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1.25rem;
	.card-trans {
		position: absolute;
		bottom: 20px;
		left: 15px;
		right: 15px;
		background: #0d102c;
		padding: 20px;
		border-radius: 7px;
	}
	.widgetbox {
		top: 0;
		left: 0;
		border-radius: 7px;
		background: $white;
		padding: 0 15px 0 15px;
	}
}

.card-blog-overlay6 {
	background: url(../images/banners/subscribe.jpg);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(107, 34, 251, 0.6);
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}

.card-aside-column1 {
	min-width: 8rem;
	width: 40%;
	-ms-flex: 0 0 40%;
	flex: 0 0 40%;
	background: no-repeat center/cover;
}

.card-blog-img {
	background: url(../images/other/about.jpg);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-6;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}

.card-item-desc .card-item-desc-1 dt,
dd {
	display: inline-block;
}

.card-item-desc {
	.card-item-desc-1 dd,
	dl {
		margin-bottom: 0;
	}
}

.card-pay .tabs-menu {
	margin-bottom: 25px;
	li {
		width: 33.3%;
		display: block;
		a {
			padding: .5rem 1rem;
			background: #f2f3f8;
			color: $black;
			display: block;
			text-align: center;
			border-right: 1px solid #f3f3f3;
			&.active {
				color: $white;
				border-radius: 3px;
				text-align: center;
			}
		}
	}
}

.card-over {
	position: relative;
	img {
		vertical-align: middle;
	}
	.content {
		position: absolute;
		bottom: 0;
		background: #374254;
		opacity: .9;
		color: $white;
		width: 100%;
		padding: 20px;
	}
}

.card-video {
	background: url(../images/products/b5.png);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-6;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}

.card-img img {
	border-radius: 3px;
}